@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, 240px);
  justify-content: center;

  @include min-1440-break {
    grid-template-columns: repeat(auto-fit, 440px);
    gap: 20px;
  }

  @include min-1920-break {
    grid-template-columns: repeat(auto-fit, 394px);
    gap: 14px;
  }
}
